ion-card {
  box-shadow: none;
  border-radius: 0.75rem;
}

app-categoria-item {
  outline: none;
  width: 100%;
}

app-small-attivita-card-item {
  outline: none;
  width: 100%;
}

app-detail-attivita-card-item {
  outline: none;
}

app-attivita-card-item {
  outline: none;
}

.scegli-categorie-filtri-modal {
  .modal-wrapper {
    position: absolute;
    bottom: 0;
    min-height: initial;
    top: initial;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    height: 60vh !important;
  }
}

.add-product-to-cart-modal {
  .modal-wrapper {
    position: absolute;
    bottom: 0;
    min-height: initial;
    top: initial;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    height: 60vh;
  }
}

ion-card.no-location-card {
  box-shadow: 5px 5px 12px var(--ion-color-ui-bg-shade), -5px -5px 12px -5px var(--ion-color-ui-light);
  margin: 0 12px;

  &.mg-t-25 {
    margin-top: 25px;
  }


  h3 {
    margin: 0;
    text-align: left;
    font-size: 0.9rem;
    padding-right: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--ion-color-medium);
  }

  ion-img {
    height: 6rem;
    padding: 0.75rem;
    opacity: 0.95;
  }
}

ion-card.continue-shopping-card {
  outline: none;
  box-shadow: 5px 5px 12px var(--ion-color-tertiary-bg-shade), -5px -5px 12px -5px var(--ion-color-tertiary-light);

  h3 {
    margin: 0;
    text-align: left;
    font-size: 0.9rem;
    padding-right: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--ion-color-light);
  }

  ion-img {
    height: 6rem;
    padding: 0.75rem;
    opacity: 0.95;
  }
}

ion-card.no-menu-card {
  box-shadow: 5px 5px 12px var(--ion-color-ui-bg-shade), -5px -5px 12px -5px var(--ion-color-ui-light);

  h3 {
    margin: 0;
    text-align: left;
    font-size: 0.9rem;
    padding-right: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--ion-color-medium);
  }

  ion-img {
    padding: 12px;
  }
}

ion-card.no-data-card {
  box-shadow: 5px 5px 12px var(--ion-color-ui-bg-shade), -5px -5px 12px -5px var(--ion-color-ui-light);

  h3 {
    margin: 0;
    text-align: left;
    font-size: 0.85rem;
    padding-right: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--ion-color-medium);
  }

  ion-img {
    padding: 12px;
  }
}
