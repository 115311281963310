ion-button{
  --border-radius: 0.75rem;
}

.custom_form{

  .item-block{

    ion-searchbar{
      --padding-bottom: 0;
    }

    ion-searchbar, ion-input{
      --background: var(--ion-color-ui);
    }

    ion-input{
      --border-radius: 0.75rem;
    }

    input, select{
      border-radius: 0.75rem;
      width: 100%;
      padding: 12px;
      border: none;
      outline: none;
      background: var(--ion-color-ui);
      margin-bottom: 0.5rem;

      &.white{
        background: #FFFFFF;
      }
    }

    .error-message{
      text-align: right;
      div{
        color: var(--ion-color-danger);
        font-size: 0.74rem;
        text-transform: uppercase;
        padding-top: 0;
      }
    }
  }
}
