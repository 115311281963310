ion-list{
  background: var(--ion-color-ui-light) !important;

  &.default-item-list{

    ion-item{
      margin-top: 8px;
      --background: var(--ion-color-ui);
      --inner-padding-end: 6px;
      --inner-padding-top: 4px;
      --inner-padding-bottom: 4px;
      --border-radius: 0.75rem;

      &:last-child{
        border-bottom: none;
      }

    }

    &.skeleton{
      ion-item{
        margin-top: 16px;
        --inner-padding-top: 0;
        --inner-padding-start: 0;
        --inner-padding-bottom: 0;
        --inner-padding-end: 0;
      }
    }
  }
}
