.spinner-center-container{
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.spinner-1-5x{
  transform: scale(1.5);
}
